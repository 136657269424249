<template>
  <div class="wrapper">
    <div class="text-h4 font-weight-bold pa-1 title">
      Изображения
    </div>
   <BasePhoto
     :accept-types="acceptTypes"
     :model-name="modelName"
     :model-id="modelId"
     :disabled="disabled"
     :multiple="multiple"
     :value="value"
     :with-description="withDescription"
     :name-object-img="nameObjectImg"
   />
  </div>
</template>

<script>
import BasePhoto from '@/components/base/BasePhoto'
export default {
  name: 'MapPhoto',
  components: { BasePhoto },
  props: {
    acceptTypes: {
      type: String,
      default: 'application/pdf'
    },
    modelName: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: false
    },
    disabled: { Boolean, default: false }, //пропс передается из PassportObjectDetail, идет проверка роли "auditor"
    multiple: { Boolean, default: true }, //(нужен для отключения multiple) пропс передается из Appeal/DetailView, как false, в самом BaseUploaderFile стоит по умолчанию true
    value: Object,
    nameObjectImg: String,
    withDescription: Boolean
  }
}
</script>
<style scoped>
  .wrapper {
    height: calc(100vh - 100px)
  }
  .title {
    color: #64C7FF;
  }
</style>